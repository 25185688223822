import * as tslib_1 from "tslib";
import { ElementRef, NgZone, OnInit } from '@angular/core';
import { Api } from '../../services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DEFAULT_WHITELABEL, findWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import { MixpanelService } from '../../services/mixpanel.service';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, api, formBuilder, route, ngZone, mixpanel) {
        var _this = this;
        this.router = router;
        this.api = api;
        this.formBuilder = formBuilder;
        this.route = route;
        this.ngZone = ngZone;
        this.mixpanel = mixpanel;
        this.validationActive = false;
        this.busy = false;
        this.loginError = null;
        this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
        localStorage.setItem('favicon', this.whitelabel_config.logos.favicon);
        localStorage.setItem('title', this.whitelabel_config.name);
        this.route.params.subscribe(function (params) {
            if (params['hash']) {
                _this.api.addInitCallback(function () {
                    _this.disable();
                    _this.api.hashLogin(params['hash']).then(function (success) {
                        if (_this.api.logged)
                            _this.router.navigateByUrl('/dashboard');
                        else
                            _this.enable();
                    }, function (error) { return _this.enable(); }).catch(function (error) { return _this.enable(); });
                });
            }
            if (params.partner_id) {
                _this.whitelabel_config = findWhiteLabel(params.partner_id);
                localStorage.setItem('favicon', _this.whitelabel_config.logos.favicon);
                localStorage.setItem('title', _this.whitelabel_config.name);
            }
        });
        this.loginForm = formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern(environment.emailValidators)]],
            password: ['', [Validators.required]]
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.initGoogle();
    };
    LoginComponent.prototype.disable = function () {
        this.busy = true;
        this.loginForm.controls.email.disable();
        this.loginForm.controls.password.disable();
    };
    LoginComponent.prototype.enable = function () {
        this.busy = false;
        this.loginForm.controls.email.enable();
        this.loginForm.controls.password.enable();
    };
    LoginComponent.prototype.eventHandler = function (event) {
        if (event.keyCode == 13)
            this.signIn();
    };
    LoginComponent.prototype.signIn = function () {
        var _this = this;
        this.validationActive = true;
        if (this.busy || !this.loginForm.valid) {
            return;
        }
        this.disable();
        var credentials = {
            email: this.loginForm.controls.email.value,
            password: this.loginForm.controls.password.value
        };
        this.api.login(credentials).pipe(tap(function (res) {
            _this.enable();
            _this.api.setUser(res.user);
            _this.api.logged = true;
            var userId = _this.api.user.id.toString();
            _this.mixpanel.identify(userId);
            var utm_source = localStorage.getItem('utm_source');
            var utm_medium = localStorage.getItem('utm_medium');
            var utm_campaign = localStorage.getItem('utm_campaign');
            _this.mixpanel.peopleSet({
                $email: _this.api.user.email,
                $name: _this.api.user.name,
                utm_source: utm_source,
                utm_medium: utm_medium,
                utm_campaign: utm_campaign
            });
            _this.mixpanel.track('User Logged In', {
                $email: _this.api.user.email,
                utm_source: utm_source,
                utm_medium: utm_medium,
                utm_campaign: utm_campaign
            });
            _this.router.navigateByUrl('/dashboard');
        }), catchError(function (err) {
            _this.loginError = err.error.message;
            _this.enable();
            _this.api.logged = false;
            return of();
        })).subscribe();
    };
    LoginComponent.prototype.initGoogle = function () {
        var _this = this;
        // @ts-ignore
        window.onGoogleLibraryLoad = function () {
            var idConfiguration = {
                client_id: environment.googleClientId,
                callback: _this.handleCredentialResponse.bind(_this),
                auto_select: false,
                cancel_on_tap_outside: true,
                use_fedcm_for_prompt: true
            };
            google.accounts.id.initialize(idConfiguration);
            google.accounts.id.renderButton(_this.loginElement.nativeElement, { theme: 'outline', size: 'large', width: 225, logo_alignment: 'center' });
            google.accounts.id.prompt();
        };
    };
    LoginComponent.prototype.handleCredentialResponse = function (response) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var credentials;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                credentials = {
                    credential: response.credential,
                    partner_id: this.whitelabel_config.id
                };
                this.api.googleAuthLogin(credentials).pipe(tap(function (res) {
                    _this.api.logged = true;
                    _this.api.setUser(res.user);
                    _this.router.navigateByUrl('/dashboard');
                }), catchError(function (err) {
                    _this.loginError = err.error.message;
                    return of();
                })).subscribe();
                return [2 /*return*/];
            });
        });
    };
    return LoginComponent;
}());
export { LoginComponent };

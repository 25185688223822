import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Memberships} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {DEFAULT_WHITELABEL, IWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import * as _ from 'lodash';
import {IDealPreviewDto} from '../models/DealPreview';
import {DealTypes} from '@dollar-flight-club/shared_utilities/lib/models/Deal';
import {map, tap} from 'rxjs/operators';
import {DealDto} from '../models/DealDto';
import {AuthResponse, Credentials, LoginCredentials, User, UserModel} from '../models/Authentication';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Airport, Destination} from '../models/FlightModels';
import {environment} from '../../environments/environment';
import contents from '../utilities/strings';
import {ApplyCouponResponse, CurrencyResponse, PauseResponse} from '../models/ReturnResponses';

declare var gtag: Function;
export const UPGRADED_EVENT_TYPE = 'upgraded'
export const PERKS_ORIGIN = 'perks'
export const CONCIERGE_ORIGIN = 'concierge'
export const PREMIUM_PLUS_MEMBERSHIPS = [Memberships.premiumPlus, Memberships.premiumPlusTrial]

export enum MessageType {
    info,
    success,
    error
}
@Injectable()
export class Api {
  alerts: { type: MessageType, message: string, duration: number, start: Date, class: string, icon: string }[];
  error = false;
  config: any;
  initialized = false;
  strings: any;
  loading = true;
  message = '';
  modalMessage = false;
  modalMessageTitle = '';
  modalMessageText = '';
  modalMessageClass = '';
  user: UserModel;
  premium: boolean = false;
  logged = false;
  initializedCallback: Function[] = [];
  confirmUpgrade = false;
  confirmUpgradeCallback: Function;
  confirmFree = false;
  freeCallback: Function;
  membershipId = Memberships.premiumTrial; //v2_annual_plan
  dealType = DealTypes.international;
  departures: boolean = true;
  modalOpen: boolean;
  userModel: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
  user$: Observable<UserModel>;
  userEmail: string;
  coupon: any;
  discount: any

  public constructor(private http: HttpClient) {
    this.alerts = [];
    this.config = environment;
    this.strings = contents.strings;


    setTimeout(() => {
      this.updateMessageQueue();
    }, 1000);

    this.user$ = this.userModel.asObservable();
    this.user$.subscribe(u => this.user = u);
    this.modalOpen = false;
    this.init();
  }

  private async init() {

    setTimeout(() => {
      this.initialized = true;
      this.loading = false;
      for (let i = 0; i < this.initializedCallback.length; i++) {
        this.initializedCallback[i]();
      }
    }, 1000);
  }

  public confirmMembershipUpgrade(callback: Function) {
    this.confirmUpgradeCallback = callback;
    this.confirmUpgrade = true;
  }

  public confirmMembershipCancellation(callback: Function) {
    this.freeCallback = callback;
    this.confirmFree = true;
    gtag('event', 'membership', {
      'event_category': 'unsubscribe',
      'event_label': 'Unsubscribe from members portal',
      'value': 69//price
    });
  }

  public getWhiteLabelId(partnerId: string, isLifetime: boolean){
    if (partnerId) {
      return partnerId;
    }
    if (isLifetime && !partnerId){
      return 'lifetimepremiumplus';
    }
    return DEFAULT_WHITELABEL;
  }

  public getDeal(id: number): Observable<DealDto> {
    return this.http.post(this.config.dealInfoUrl, {id},
      {withCredentials: true}
    ).pipe(
      map((response: { data }) => response.data)
    )
  }

  public showModalMessage(title: string, text: string, type: string = 'success') {
    this.modalMessage = false;
    this.modalMessageTitle = title;
    this.modalMessageText = text;
    this.modalMessageClass = type;
    this.modalMessage = true;
  }

  public addInitCallback(callback: Function) {
    if (!this.initialized)
      this.initializedCallback.push(callback);
    else
      callback();
  }

  /**
   * Set the user via observable so that components can subscribe
   * @param userData logged in user data
   */
  setUser(userData: User) {
    if (typeof userData.signupDate === "string") {
      userData.signupDate = Math.floor(new Date(userData.signupDate).getTime());
    }
    if (userData.membershipId > 1) {
      this.premium = true
    }
    this.userModel.next(new UserModel(userData))
    gtag('set', {'user_id': _.get(this.user, 'id')});
  }

  public async checkMembership(email: string): Promise<User | boolean> {
    try {
      const res = await this.http.post<any>(this.config.publicCheckMembership, {email: email}, {withCredentials: true}).toPromise()
      this.setUser(res)
      return res
    } catch (error) {
      return false;
    }
  }

  public signup(
    email: string,
    password: string,
    options?: {
      partnerId?: string
      source?: string,
      medium?: string,
      rfsn?: boolean
    }
  ): Observable<AuthResponse> {
    //this.partner_id = 'blah';//TODO: Set from returned user object
    return this.http.post<AuthResponse>(
      this.config.apiSignupUrl,
      {
        email: email,
        password: password,
        partner_id: options.partnerId || DEFAULT_WHITELABEL,
        source: options.source,
        medium: options.medium,
        rfsn: options.rfsn
      },
      {withCredentials: true}
    )
  }

  public updateAccountInfo(user: Partial<User>) {
    return this.http.post(this.config.updateAccountUrl, user, {withCredentials: true}).toPromise().then(async (res) => {
      const responseInfo: any = res;
      if (!responseInfo)
        return false;
      else {
        this.user.phone = responseInfo.phone
        this.setUser(responseInfo);
        return true;
      }
    }).catch((error) => {
      return false;
    });
  }

  public async newsLetterSignup(newsletters: { [newsletterId: string]: boolean }) {
    try {
      return await this.http.post(this.config.newsLetterSignupUrl, newsletters, {withCredentials: true}).toPromise().then(res => {
        return true;
      });
    } catch (error) {
      return false;
    }
  }


  toggleNotifications(enable: boolean) {
    const body  ={ type: 'email' };
    const url = enable ? this.config.activateNotificationsUrl: this.config.deactivateNotificationsUrl;

    return this.http.post(url, body, { withCredentials: true }).pipe(
      tap(() => {
        this.user.emailNotifications = enable;
      })
    );
  }

  public updateRegion(region: string) {
    return this.http.post(this.config.updateRegionUrl, {region}, {withCredentials: true}).toPromise().then((res) => this.user.region = region).catch((error) => {
    });
  }

  public login(credentials: LoginCredentials)
    : Observable<AuthResponse> {
    return this.http.post<AuthResponse>(this.config.membersLoginUrl, credentials, {withCredentials: true});
  }

  public googleAuthLogin(credentials: Credentials)
    : Observable<AuthResponse> {
    return this.http.post<AuthResponse>(this.config.googleLogin, credentials, {withCredentials: true});
  }

  public googleAuthSignUp(credentials: Credentials)
    : Observable<AuthResponse> {
    return this.http.post<AuthResponse>(this.config.googleSignup, credentials, {withCredentials: true});
  }

  public hashLogin(hash: string) {
    return this.http.post(this.config.hashLoginUrl, {hash: hash}, {withCredentials: true})
      .toPromise().then((res) => {
        const responseInfo: any = res;
        if (responseInfo.error) {
          return false;
        } else {
          this.setUser(responseInfo.data);
          this.logged = true;
          return true;
        }
      }).catch((error) => {
        this.logged = false;
        return false;
      });
  }

  public recover(email: string) {
    return this.http.post(this.config.membersRecoverUrl, {email: email}, {withCredentials: true})
      .toPromise().then((res) => {
        return true;
      }).catch((error) => {
        return false;
      });
  }

  public validateUserToken(userToken: string) {
    return this.http.post(this.config.resetTokenValidationUrl, {
      link: userToken,
    }, {withCredentials: true})
      .toPromise().then((res) => {
        const responseInfo: any = res;
        return !responseInfo.error;

      }).catch((error) => {
        return false;
      });
  }

  public unsubscribe() {
    return this.http.post(this.config.membershipCancelationUrl, {}, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      return !responseInfo.error;
    }).catch((error) => {
      return false;
    });
  }

  /**
   *Subscribe is for net new customers with token
   */
  public subscribe(coupon: string, membershipId = this.membershipId) {
    return this.http.post(this.config.membershipSubscriptionUrl, {
      membership: membershipId,
      coupon
    }, {withCredentials: true})
      .toPromise().then((res) => {
        const responseInfo: any = res;
        return responseInfo;
      }).catch((error) => {
        return false;
      });
  }

  public setupIntents(user: UserModel){
    return this.http.post(this.config.setupIntentUrl, {user: user}, {withCredentials: true}).toPromise().then(res => {
      const responseInfo: any = res;
      return responseInfo
    })
  }

  /**
   *Upgrade is for users that are upgrading to a new stripe plan
   */
  public upgrade(user: UserModel, selectedPlanId: number, whitelabel: IWhiteLabel, coupon: string, paymentIntentId: string, trialCode: TrialCodes = null, paymentMethod: any, lifetime: boolean, location: string, updateBilling: boolean, utm_source: string, utm_medium: string, trafficSource: string) {
    return this.http.post(this.config.membershipUpgradeUrl, {
      user,
      selectedPlanId,
      whitelabel,
      coupon,
      paymentIntentId,
      trialCode,
      paymentMethod,
      lifetime,
      location,
      updateBilling,
      utm_source,
      utm_medium,
      trafficSource
    }, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      return responseInfo

    }).catch((error) => {
      return error;
    });
  }

  applyCoupon(coupon: string): Observable<ApplyCouponResponse> {
    return this.http.post<ApplyCouponResponse>(this.config.membershipCouponUrl, {
      membership: this.membershipId, coupon}, { withCredentials: true });
  }

  pause(): Observable<PauseResponse> {
    return this.http.post<PauseResponse>(this.config.membershipPause, {}, { withCredentials: true })
  }

  extendTrial(): Observable<void> {
    return this.http.post<void>(this.config.extendTrialUrl, {}, { withCredentials: true })
  }

  public membershipInfo() {
    return this.http.post(this.config.membershipInfoUrl, {}, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      if (responseInfo.error) {
        return null;
      } else {
        return responseInfo;
      }
    }).catch((error) => {
      return false;
    });
  }

  public resetPassword(newPassword: string, userToken: string) {
    return this.http.post(this.config.passwordResetUrl, {link: userToken, password: newPassword},
      {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      return !responseInfo.error;
    }).catch((error) => {
      return false;
    });
  }

  public logout() {
    this.showPageLoader();
    return this.http.post(this.config.membersLogoutUrl, {}, {withCredentials: true}).toPromise().then((res) => {
      this.logged = false;
      document.location.href = this.config.public_area;
    }).catch((error) => {
      this.logged = false;
      document.location.href = this.config.public_area;
    });
  }

  public getFavoriteDestinations() {
    return this.http.post(this.config.favoriteDestinationsListUrl, {}, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      if (responseInfo.error)
        return [];
      return responseInfo;
    }).catch((error) => {
      return [];
    });
  }

  public getFavoriteAirlines() {
    return this.http.post(this.config.favoriteAirlinesListUrl, {}, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      if (responseInfo.error)
        return [];
      return responseInfo.data.list;
    }).catch((error) => {
      return [];
    });
  }

  public getFavoriteDepartures() {
    return this.http.post(this.config.favoriteDeparturesListUrl, {}, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      if (responseInfo.error) {
        this.departures = false
        return [];
      }
      if (responseInfo.length === 0) {
        this.departures = false
        return []
      }
      return responseInfo;
    }).catch((error) => {
      return [];
    });
  }

  public findAirports(search: string): Observable<Airport[]> {
    return this.http.post<any>(this.config.apiAirportsSearchUrl, {
        search: search,
        count: 5,
      },
      {withCredentials: true}
    ).pipe(
      //TODO: Remove this once we change endpoint res to just airport[]
      map(res => res.data.list)
    );
  }

  public findDestinations(search: string): Observable<Destination[]> {
    return this.http.post<any>(this.config.apiSearchDestinations, {
        query: search,
        results: 5,
      },
      {withCredentials: true}
    ).pipe(
      //TODO: Remove this once we change endpoint res to just destination[]
      map(res => res.data)
    );
  }

  //TODO: Replace current endpoint used in the dashboard to set departure airport with this
  public setDepartureAirport(selectedAirport: Airport): Observable<Boolean> {
    return this.http.post<any>(this.config.favoriteDeparturesAddUrl, {
        airports: [selectedAirport.ID]
      },
      {withCredentials: true}
    );
  }

  //TODO: Replace current endpoint used in the dashboard to set destination airport with this
  public setDestination(destinationId: number): Observable<Boolean> {
    return this.http.post<any>(this.config.favoriteDestinationsAddUrl, {
        id: destinationId
      },
      {withCredentials: true}
    );
  }

  //TODO: Replace current endpoint used in the dashboard to remove dream destination with this
  public removeDreamDestination(id: number) {
    return this.http.post(this.config.favoriteDestinationsRemoveUrl, {
        id
      },
      {withCredentials: true}
    );
  }

  public addFavoriteDestination(id: number) {
    return this.http.post(this.config.favoriteDestinationsAddUrl, {id}, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      if (responseInfo.error)
        return false;
      return true;
    }).catch((error) => {
      return false;
    });
  }

  public addFavoriteAirline(id: number) {
    return this.http.post(this.config.favoriteAirlinesAddUrl, {id}, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      if (responseInfo.error)
        return false;
      return responseInfo.data;
    }).catch((error) => {
      return false;
    });
  }

  public addFavoriteDeparture(id: number) {
    return this.http.post(this.config.favoriteDeparturesAddUrl, {id}, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      if (responseInfo.error)
        return false;
      return true
    }).catch((error) => {
      return false;
    });
  }

  public removeFavoriteDestination(id: number) {
    return this.http.post(this.config.favoriteDestinationsRemoveUrl, {id}, {withCredentials: true}).toPromise().then((res) => {
      {
        const responseInfo: any = res;
        if (responseInfo.error)
          return false;
        return true;
      }
    }).catch((error) => {
      return false;
    });
  }

  public removeFavoriteAirline(id: number) {
    return this.http.post(this.config.favoriteAirlinesRemoveUrl, {id}, {withCredentials: true}).toPromise().then((res) => {
      {
        const responseInfo: any = res;
        if (responseInfo.error)
          return false;
        return true;
      }
    }).catch((error) => {
      return false;
    });
  }

  public removeFavoriteDeparture(id: number) {
    return this.http.post(this.config.favoriteDeparturesRemoveUrl, {id}, {withCredentials: true}).toPromise().then((res) => {
      {
        const responseInfo: any = res;
        if (responseInfo.error)
          return false;
        return true;
      }
    }).catch((error) => {
      return false;
    });
  }

  public getLatestDeals(): Observable<IDealPreviewDto[]> {
    return this.http.get<any>(this.config.latestDealsUrl, { withCredentials: true })
  }


  public getDealsList(page) {
    return this.http.post(this.config.dealsListUrl, {
      page: page,
      size: 24,
      type: this.dealType
    }, {withCredentials: true}).toPromise().then((res) => {
      const responseInfo: any = res;
      if (responseInfo.error)
        return false;
      return responseInfo.data;
    }).catch((error) => {
      return false;
    });
  }


  public checkCoupon(coupon: string): Observable<any> {
    return this.http.post(this.config.checkCouponUrl, { coupon })
  }

  public getPlanPrice(id: string): Observable<number> {
    return this.http.get<{ price: number }>(this.config.getPlanPriceUrl, { params: { planId: id } }).pipe(
      map(responseInfo => responseInfo.price)
    );
  }


  public async getInvoicePrice(plan: string): Promise<number> {
    const res = await this.http.post<any>(this.config.getInvoicePriceUrl, { plan: plan }, { withCredentials: true }).toPromise();
    return res.unit_amount / 100;
  }

  public travelProductCheckout(email: string, partnerId: string, coupon: string, source: string, medium: string) {
    return this.http.post(this.config.createCheckoutSession, {email: email, partnerId: partnerId, coupon: coupon, utmSource: source, utmMedium: medium}, {responseType: "text"})
  }

  public perkProductCheckout(email: string, source: string, medium: string, partnerId: string, coupon: string) {
    return this.http.post(this.config.createPerkCheckoutSession, {email: email, utmSource: source, utmMedium: medium, partnerId: partnerId, coupon: coupon}, {responseType: "text"})
  }

  public showPageLoader(message: string = '') {
    this.message = message.length ? message : 'Please wait';
    this.loading = true;
  }

  public hidePageLoader() {
    this.message = '';
    this.loading = false;
  }

  public scrollTop() {
    window.scrollTo(0, 0);
  }

  public showMessage(type: MessageType, message: string, duration: number = 10) {
    const alert = {
      type: type,
      message: message,
      duration: duration,
      start: new Date(),
      class: 'success',
      icon: 'fa fa-check'
    };
    switch (type) {
      case MessageType.error:
        alert.class = 'error';
        alert.icon = 'fa fa-warning';
        break;
      case MessageType.info:
        alert.class = 'info';
        alert.icon = 'fa fa-info-circle';
        break;
    }
    this.alerts.push(alert);
  }

  public updateMessageQueue() {

    const currentTime: number = Date.now();
    if (this.alerts) {
      for (let i = 0; i < this.alerts.length; i++) {
        if ((currentTime - this.alerts[i].start.getTime()) / 1000 >= this.alerts[i].duration)
          this.alerts.splice(i, 1);
      }
    }

    setTimeout(() => {
      this.updateMessageQueue();
    }, 1000);
  }

  public removeAlert(index: number) {
    if (index < this.alerts.length)
      this.alerts.splice(index, 1);
  }
  public async updateCartId (id: number, cartId: string) {
    const response = await this.http.post<any>(this.config.updateCartIdUrl, {userId: id, cartId: cartId}, {withCredentials: true}).toPromise()
    if(response.error){
      return
    }
    return response
  }
  public async setReferral(id: number, eventType: string) {
    return await this.http.post<any>(this.config.sendReferralUrl, {id, eventType}, {withCredentials: true}).toPromise()
  }

  public genUniqueId(): string {
    const dateStr = Date
      .now()
      .toString(36);

    const randomStr = Math
      .random()
      .toString(36)
      .substring(2, 8);

    return `${dateStr}-${randomStr}`;
  }

  public async getCurrentUser(): Promise<User> {
    return this.http.post<User>(`${environment.baseUrl}/dfcmembers/session`, {}, {withCredentials: true}).pipe(
      tap(user => {
        this.userModel.next(user)
      })
    ).toPromise();
  }

  public getNearestAirports(lat?: number, lon?: number): Observable<any> {
    let params = new HttpParams();
    if (lat !== undefined && lat !== null) {
      params = params.set('lat', lat.toString());
    }
    if (lon !== undefined && lon !== null) {
      params = params.set('lon', lon.toString());
    }
    return this.http.get(this.config.getNearestAirportsUrl, { params, withCredentials: true });
  }
  public getPopularDestinations(){
    return this.http.get(this.config.findPopularDestinationsUrl, {withCredentials: true})
  }

  public getIPLocation(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => resolve(position),
          (error: GeolocationPositionError) => reject(error)
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  public getCurrencyExchangeRate(currency: string): Observable<CurrencyResponse>{
    const params = {currency: currency}
    return this.http.get<CurrencyResponse>(this.config.getCurrencyUrl, {params, withCredentials: true})
  }

  public getAllPerks(): Observable<any> {
    return this.http.get(this.config.getAllPerksUrl, {withCredentials: true})
  }

  public getPerk(perkName: string): Observable<any> {
    return this.http.get(this.config.getPerkUrl, {params: {name: perkName}, withCredentials: true})
  }

  public getUserPerkProductSubscription(product: string): Observable<any> {
    const params = {product: product}
    return this.http.get(this.config.getUserPerkProductSubscriptionUrl, {params, withCredentials: true})
  }
}
  export enum TrialCodes {
  TwoWeekTrial = 'p2WkZisyJ',
  OneMonthTrial = 'p1AkZisyJ',
  ThreeMonthTrial = 'p3oUmcydb',
  SixMonthTrial = 'p6TbprZPt'
}

import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() {
    mixpanel.init(environment.mixPanelToken , {
      persistence: "localStorage",
      api_host: `${environment.baseUrl}/proxy`
    });
  }

  track(msg: string, properties: any = {}){
    mixpanel.track(msg, properties);
  }

  peopleSetOnce(properties: any = {}){
    mixpanel.people.set_once(properties);
  }

  peopleSet(properties: any = {}){
    mixpanel.people.set(properties);
  }

  identify(id: string){
    mixpanel.identify(id);
  }
  alias(newId: string, oldId?: string){
    if (oldId) {
      mixpanel.alias(newId, oldId);
    } else {
      mixpanel.alias(newId);
    }
  }
  getDistinctId(): string {
    return mixpanel.get_distinct_id();
  }
}
